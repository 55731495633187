export const containerSourceFields = [
  'containerNumber',
  /* 'bolNumber', */
  'vesselName',
  'destinationTerminalCode',
  'eta',
  'etaTimeStamp',
  'ata',
  'ataTimeStamp',
  'dischargeDateTime',
  'outgateDateTime',
  'shipmentStatusCd',
  'isContainerDocked',
  'customsHoldFlag',
  'customsHoldCodes',
  'terminalHoldFlag',
  'terminalHoldCodes',
  'otherHoldFlag',
  'otherHoldCodes',
  'pendingCustHoldFlag',
  'pendingCustHoldCodes',
  'currentPosition',
  'equipmentCode',
  'equipmentCategory',
  'isAvailableForPickup',
  'lastFreeDay',
  'shipperName',
  'shippingLineName',
  'voyageNumber',
  'isHazardous',
  'hazMatClassCode',
  'berthCode',
  'destinationMode',
  'chassisNumber',
  'shippingLineSCAC',
  'truckerSCAC',
  'railRoadSCAC',
  'vesselOwnerSCAC',
  'vesselCode',
  'containerTripId',
  'containerStatus',
  'ataOrAtaTimeStamp',
  'etaOrEtaTimeStamp',
  'outgate',
  'tripId',
  'appointmentStatus',
  'appointmentStatusMapper',
  'appointmentDate',
  'appointmentSlotEndDateTime',
  'avgBerthTime',
  'avgDischargeTime',
  'avgLoadTime',
  'ingateTerminalCd',
  'ingateDateTime',
  'outgateDateTimeByTruck',
  'totalTime',
  'carrierName',
  'outgateTerminalCd',
  'portDestination',
  'equipmentCategoryDescription',
];

export const applySort = {
  'etaAta': 'sortOnScriptField',
  'holdStatusAsEnum': 'sortOnScriptField',
  'containerNumber': 'containerNumber',
  'equipmentCode': 'equipmentCategory',
  'equipmentCategory': 'equipmentCategory',
  'unloadEvent': 'sortOnScriptField',
  'location': 'sortOnScriptField',
  'destinationTerminal': 'destinationTerminalCode',
  'vesselName': 'vesselName',
  'lastFreeDay': 'sortOnScriptField',
  'eventdate': 'sortOnScriptField',
  'terminalName': 'destinationTerminalCode',
  //'bolNumbers': 'bolNumber',
  'shippingLine': 'shippingLineName',
  'apptStatus': 'appointmentStatusMapper',
  'apptTime': 'appointmentDate',
  'averageBerthTime':'vesselCodeData.avgBerthTime',
  'outgateDateTime': 'outgateDateTime',
  'outgateDateTimeByTruck':'outgateDateTimeByTruck',
  'averageDischargeTime':'vesselCodeData.avgDischargeTime',
  'averageLoadTime':'vesselCodeData.avgLoadTime',
  'ingateTerminalCd': 'ingateTerminalCd',
  'ingateDateTime': 'ingateDateTime',
  'totalTime':'totalTime',
  'carrierName': 'carrierName',
  'outgateTerminalCd': 'outgateTerminalCd',

};

export const searchFilters = [
  {
    'importExport': {
      'key': 'importExport',
      'values': ['I'],
      'comparison': 'EQUAL',
    },
    'portDestination': {
      'key': 'portDestination',
      'values': ['2704','2709'],
      'comparison': 'EQUAL',
    },
    'VESSEL_CODE': {
      'possibleValues': {},
      'filter': {
        'key': 'vesselCode',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'containerNumber': {
      'possibleValues': {},
      'filter': {
        'key': 'containerNumber',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'UNLOAD_EVENT': {
      'possibleValues': {
        'On Vessel': ['NA'],
        'Docked': ['NA'],
        'Discharged': ['UV'],
        'Out Gated': ['OA', 'AL', 'RL'],
        'Truck Outgated': ['OA'],
        'Truck Returned': ['OA', 'I'],
      },
      'filter': {
        'key': 'shipmentStatusCd',
        'values': [],
        'comparison': 'EQUAL',
      },
      'searchFilter': {
        'key': 'shipmentStatusCd',
        'values': [],
        'comparison': 'SHOULD',
      },
    },
    'HOLDS': {
      'possibleValues': {
        'CUSTOMS': 'customsHoldFlag',
        'TERMINAL': 'terminalHoldFlag',
        'NONE': 'otherHoldFlag',
      },
      'filter': {
        'key': '',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'pendingContainerDownload': {
      'filter': {
        'key': 'containerNumber',
        'values': [],
      },
    },
    'containerTripId': {
      'filter': {
        'key': 'containerTripId',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'vesselNameFilter': {
      'filter': {
        'key': 'vesselName',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'terminalFilter': {
      'filter': {
        'key': 'destinationTerminalCode',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
 /*    'bolNumberFilter': {
      'filter': {
        'key': 'bolNumber',
        'values': [],
        'comparison': 'EQUAL',
      },
    }, */
    'available': {
      'filter': {
        'key': 'isAvailableForPickup',
        'values': ['Available'],
        'comparison': 'EQUAL',
      },
      'eliminateOugated': {
        'key': 'shipmentStatusCd',
        'values': ['NA', 'UV'],
        'comparison': 'EQUAL',
      },
    },
    'owner': {
      'filter': {
        'key': 'ownerId',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'workingVessels': {
      'filter': {
        'key': 'workingVessels',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'priority': {
      'filter': {
        'key': 'priority',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'containerSize': {
      'filter': {
        'key': 'containerLength',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'universalSearch': {
      'filter': {
        'key': 'containerNumber,destinationTerminalCode,vesseName',
        'values': [],
        'comparison': 'CASE_INSENSITIVE_REGEX',
      },
    },
    'TRIP_ID': {
      'filter': {
        'key': 'tripId',
        'values': [],
        'comparison': 'EQUAL',
      },
    },
    'containerSearch' : {
      'filter' :{
        'key': 'containerNumber',
        'values': [],
        'comparison': 'CASE_INSENSITIVE_REGEX',
      },
    },
  },
];

export const customHoldCodesRequest = {
  'from': 0,
  'size': 100000,
  'sourceFields': [
    'custCode',
    'holdRelDesc',
  ],
  'search': [
    {
      'key': 'custCode',
      'values': [],
      'comparison': 'EQUAL',
    },
  ],
};

export enum containerStatusEnum {
  ON_VESSEL = 'On Vessel',
  DOCKED = 'Docked',
  OUTGATED = 'Outgated',
  DISCHARGED = 'Discharged'
}

export enum containerHoldsEnum {
  TERMINAL = 'TERMINAL',
  CUSTOMS = 'CUSTOMS',
  NONE = 'NONE'
}

export const pottParsedResponseObject = {
  containerNumber: null,
  //bolNumbers: [],
  vesselName: '-',
  terminalName: '-',
  eta: null,
  ata: null,
  etaAta: '-',
  holds: 'NONE',
  owner: {
    email: '',
    familyName: '',
    fullName: '',
    givenName: '',
    id: '',
  },
  flag: null,
  customFields: {
    customField1: null,
    customField2: null,
  },
  customsHoldCodes: null,
  terminalHoldCodes: null,
  pendingCustHoldCodes: null,
  otherHoldCodes: null,
  customsHoldFlag: null,
  terminalHoldFlag: null,
  otherHoldFlag: null,
  eventDate: null,
  location: null,
  note: {
    body: null,
    editedOn: null,
  },
  isAvailableForPickup: false,
  typeCode: '-',
  holdStatus: '-',
  lastFreeDay: null,
  shipmentStatusCode: null,
  berth: null,
  chassisNumber: null,
  destination: null,
  origin: null,
  hazardous: null,
  modalityWithScac: null,
  shippingLine: null,
  vessel: null,
  voyageNumber: null,
  yardLocation: null,
  terminalHold: null,
  isPendingContainer: false,
  unloadEvent: '-',
};

export const pendingContainersSortMappingField = {
  'containerNumber': 'container',
  'owner.fullName': 'owner',
  'customField1': 'custom1',
  'customField2': 'custom2',
  'flag': 'priority',
};

export const customDataSortMappingField = {
  'owner.fullName': 'owner',
  'customField1': 'custom1',
  'customField2': 'custom2',
  'flag': 'priority',
};

export const universalSearchCodes = ['TERMINAL_NAME', 'VESSEL_NAME'];

export const universalSearchResponseObject = {
  containerResults: [],
  bolResults: [],
  vesselResults: [],
  terminalResults: [],
  customField1Results: [],
  customField2Results: [],
};

export const universalSearchRequestObject = {
  'searchRequests': [
    {
      'fromDate': '',
      'toDate': '',
      'fetchNonOrRecentlyOutgatedContainers': true,
      'searchParameters': [
        {
          'size': 10,
          'key': 'byVesselName',
          'children': [],
          'filters': [
            {
              'filterKey': 'importExport',
              'filterValues': ['I'],
            },
            {
              'filterKey': 'shipmentStatusCd',
              'filterValues': [
                'NA',
                'UV',
                'OA',
                'AL',
                'RL',
              ],
            },
            {
              'filterKey': 'vesselName',
              'filterValues': [],
              'comparison': 'CASE_INSENSITIVE_REGEX',
            },
            {
              "filterKey": "portDestination",
              "filterValues": [
                  "2704","2709"
              ]
          },
          ],
        },
      ],
    },
    {
      'fromDate': '',
      'toDate': '',
      'fetchNonOrRecentlyOutgatedContainers': true,
      'searchParameters': [
        {
          'size': 10,
          'key': 'byTerminal',
          'children': [],
          'filters': [
            {
              'filterKey': 'importExport',
              'filterValues': ['I'],
            },
            {
              'filterKey': 'shipmentStatusCd',
              'filterValues': [
                'NA',
                'UV',
                'OA',
                'AL',
                'RL',
              ],
            },
            {
              'filterKey': 'destinationTerminalCode',
              'filterValues': [],
              'comparison': 'CASE_INSENSITIVE_REGEX',
            },
            {
              "filterKey": "portDestination",
              "filterValues": [
                  "2704","2709"
              ]
          },
          ],
        },
      ],
    },
    {
      'fromDate': '',
      'toDate': '',
      'fetchNonOrRecentlyOutgatedContainers': true,
      'searchParameters': [
        {
          'size': 10,
          'key': 'byContainer',
          'children': [],
          'filters': [
            {
              'filterKey': 'importExport',
              'filterValues': ['I'],
            },
            {
              'filterKey': 'shipmentStatusCd',
              'filterValues': [
                'NA',
                'UV',
                'OA',
                'AL',
                'RL',
              ],
            },
            {
              'filterKey': 'containerNumber',
              'filterValues': [],
              'comparison': 'CASE_INSENSITIVE_REGEX',
            },
            {
              "filterKey": "portDestination",
              "filterValues": [
                  "2704","2709"
              ]
          },
          ],
        },
      ],
    },
    {
      'fromDate': '',
      'toDate': '',
      'fetchNonOrRecentlyOutgatedContainers': true,
      'searchParameters': [
        {
          'size': 10,
          /* 'key': 'byBOL', */
          'children': [],
          'filters': [
            {
              'filterKey': 'importExport',
              'filterValues': ['I'],
            },
            {
              'filterKey': 'shipmentStatusCd',
              'filterValues': [
                'NA',
                'UV',
                'OA',
                'AL',
                'RL',
              ],
            },
            {
             /*  'filterKey': 'bolNumber', */
              'filterValues': [],
              'comparison': 'CASE_INSENSITIVE_REGEX',
            },
            {
              "filterKey": "portDestination",
              "filterValues": [
                  "2704","2709"
              ]
          },
          ],
        },
      ],
    },
  ],
};

export const sortOnScriptFieldValues = {
  'etaAta': 'etaAta',
  'holdStatusAsEnum': 'holds',
  'lastFreeDay': 'lastFreeDays',
  'location': 'yardLocation',
  'unloadEvent': 'containerStatus',
  'eventdate': 'eventDate',
};

