import { Injectable } from '@angular/core';
import { IOptionItem } from '@models/dashboard/tree-option-filter.model';
import { MY_CONTAINERS, AVAILABLE } from '@models/dashboard/filters.model';
import { Store, select } from '@ngrx/store';
import { UserState } from '@app/store/user/user.state';
import { take } from 'rxjs/operators';


export type TerminalData = { terminalCode: string; terminalName: string; };
type metricsCache = {
  [terminalCode: string]: { [keys: string]: string }[]
};

const DAY_LENGTH = 60 * 60 * 24 * 1000;
const INTERVAL_IN_DAYS = 90;

@Injectable({
  providedIn: 'root',
})
export class TerminalMetricsService {
  private cache: metricsCache;
  constructor(
    private  userStore: Store<UserState>,
  ) { }
  
  public getTerminalList({ responsehelp }: any): TerminalData[] {
    if (responsehelp && responsehelp.byTerminal) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return Object.keys(responsehelp.byTerminal)
        .map(terminalCode =>
          ({
            terminalCode: terminalCode,
            terminalName: responsehelp.byTerminal[terminalCode],
          }),
        )
        .sort((a, b) => a.terminalCode > b.terminalCode ? 1 : -1);
    }
  }

  public createRequestPayload = ({ filters }) => {
    const payload: any = {
      fromDate: `${new Date(new Date().getTime() - INTERVAL_IN_DAYS * DAY_LENGTH).toISOString().slice(0, 11)}00:00:00`,
      toDate: `${new Date(new Date().getTime() + INTERVAL_IN_DAYS * DAY_LENGTH).toISOString().slice(0, 11)}23:59:59`,
      fetchNonOrRecentlyOutgatedContainers: true,
      searchParameters: [
        {
          key: 'byTerminal',
          children: [
            {
              key: 'byDischargeDateTime',
              children: [],
              'firstSortPreference': {
                'orderBy': 'KEY',
                'orderByDirection': 'ASC',
              },
              orderByCount: true,
              filters: [{ filterKey: 'shipmentStatusCd', filterValues: ['UV'] }],
            },
            {
              key: 'byStatus',
              children: [{ key: 'byInboundDockedContainers', children: [] }],
            },
            {
              key: 'byAvailableForPickup',
              children: [{ key: 'byStatus', children: [], filters: [{ filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] }] }],
            },
          ],
          filters: [{ filterKey: 'importExport', filterValues: ['I'] }, { filterKey: 'portDestination', filterValues: ['2704', '2709'] }],
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          customFieldsFilter: { ...this.withMyContainerPayload(filters) },
        },
      ],
    };

    if (filters.includes(AVAILABLE)) {
      payload.searchParameters[0].filters.push({
        filterKey: "isAvailableForPickup",
        filterValues: ['Available'],
      });
    }

    const destinationTerminalCodes = [
      filters.find(values => (values !=="My Containers") && (values !=="Available"))? filters.find(values => (values !=="My Containers") && (values !=="Available")):'',
    ].filter(value => value !== '');

    if (destinationTerminalCodes.length > 0) {
      payload.searchParameters[0].filters.push({
        filterKey: "destinationTerminalCode",
        filterValues: destinationTerminalCodes,
      });
    }

    return payload;
  }

  private withMyContainerPayload = (filters: string[]) =>
    (
      filters && filters.includes(MY_CONTAINERS)
        ? {
          filterOnCustomFields: true,
          filterOnCustomFieldsData: [{
            filterKey: 'ownerId',
            filterValues: [this.getUserID()],
            comparison: 'EQUAL',
          }],
        }
        : {}
    )

  private getUserID(): string {
    let userAttributes;
    this.userStore.pipe(select('user'), take(1)).subscribe(
      data =>  userAttributes = data['user'].userDetails,
   );
    return userAttributes.idUser;
  }

  public parseTerminalMetricsResponse = ({ filters }) => (response: any) => {
    if (response && response.responsehelp && response.responsehelp.byTerminal) {
      const data: object = response.response.aggregations.filter.byTerminal.buckets
        .reduce((acc: object, curr: any) => {
          if (filters.length === 2) {
            const { primary } = this.cache[curr.key][0];
            return primary ? { ...acc, [curr.key]: this.cache[curr.key] } : acc;
          } else if (filters.includes(AVAILABLE)) {
            const [withAvailableContainer] = curr.byAvailableForPickup.buckets.filter(status => status.key);
            return withAvailableContainer ? { ...acc, [curr.key]: this.parseTerminal(curr) } : acc;
          } else if (filters.includes(MY_CONTAINERS)) {
            return { ...acc, [curr.key]: this.cache[curr.key] };
          }

          return { ...acc, [curr.key]: this.parseTerminal(curr) };
        }, {});

      if (!this.cache) {
 this.cache = { ...data };
}

      const terminalCodes = Object.keys(data);
      const terminalList = this.getTerminalList(response).filter(({ terminalCode }) => terminalCodes.includes(terminalCode));
      const filterSet = this.createFilterSet(terminalList);

      return { data, terminalList, filterSet };
    }
  }

  private createFilterSet(data: TerminalData[]): IOptionItem[] {
    const duplicatedProps: Partial<IOptionItem> = {
      children: [],
      expandable: false,
      disabled: false,
    };

    return data.map(({ terminalName: title, terminalCode: value }) =>
      ({
        title,
        value,
        children: [{ title, value, ...duplicatedProps } as IOptionItem],
        expandable: false,
        disabled: false,
      }),
    );
  }

  private getExcessiveDwellDays (dwellDaysDate: number) {
    const currentDate: Date = new Date();
    return Math.floor((currentDate.getTime() - dwellDaysDate)/86400000);
  }

  private parseTerminal = (terminalData: any) =>
    ([
      {
        title: 'Available containers',
        primary: terminalData.byAvailableForPickup.buckets
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .filter(({ key_as_string, filter }) => key_as_string === 'AVAILABLE' && !!filter.doc_count)
          .reduce((sum: number, { filter }) => sum + filter.doc_count, 0),
        secondary: `/${terminalData.doc_count}`,
      },
      {
        title: 'On vessel',
        primary: terminalData.byStatus.buckets
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .filter(({ key_as_string }) => key_as_string === 'Inbound')
          .map(bucket => bucket.byInboundDockedContainers.buckets
            .filter(innerBucket => innerBucket.key_as_string === 'ON VESSEL')
            // eslint-disable-next-line @typescript-eslint/naming-convention
            .reduce((sum: number, { doc_count }) => sum + doc_count, 0))
          .reduce((sum: number, curr: number) => sum + curr, 0),
      },
      {
        title: 'Docked',
        primary: terminalData.byStatus.buckets
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .filter(({ key_as_string }) => key_as_string === 'Inbound')
          .map(bucket => bucket.byInboundDockedContainers.buckets
            .filter(innerBucket => innerBucket.key_as_string === 'DOCKED')
            // eslint-disable-next-line @typescript-eslint/naming-convention
            .reduce((sum: number, { doc_count }) => sum + doc_count, 0))
          .reduce((sum: number, curr: number) => sum + curr, 0),
      },
      {
        title: 'Discharged',
        primary: terminalData.byStatus.buckets
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .filter(({ key_as_string }) => key_as_string === 'Unloaded')
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .reduce((sum: number, { doc_count }) => sum + doc_count, 0),
      },
      {
        title: 'Out Gated',
        primary: terminalData.byStatus.buckets
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .filter(({ key_as_string }) => key_as_string === 'Outgate-Truck' || key_as_string === 'Outgate-Rail' || key_as_string === 'Rail Departure from Intermodal Ramp')
          // eslint-disable-next-line @typescript-eslint/naming-convention
          .reduce((sum: number, { doc_count }) => sum + doc_count, 0),
      },
      {
        title: 'Excessive dwell',
        primary: terminalData.filter.byDischargeDateTime.buckets.length
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          ? this.getExcessiveDwellDays(terminalData.filter.byDischargeDateTime.buckets[0].key)
          : 0,
        secondary: 'days',
      },
    ])
}
